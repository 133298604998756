<template>
	<div style="background: #F8FBFF;padding-bottom: 19PX;min-height: 100vh;">
		<H5header :type="2"></H5header>
		<div class="theleftside">
			<div>
				<div class="title top">
					<img src="../../assets/image/title1.png" alt="">
					<p>公示信息</p>
				</div>
				<div class="box">
					<div style="width: 100%;height: 17PX;"></div>
					<div class="box-back">
						<img :src="date.userInfo.avatar" alt="" class="box-logo">
						<div class="box-title">
							<div class="name">
								<div class="xm-box">
									<img src="../../assets/image/user.png" alt="">
									<div class="xm">姓名：</div>
								</div>
								<div class="xm" v-if="realnameLength">{{ date.userInfo.realname }}</div>
								<div class="xm xms" v-else @click="getcertificate(date.userInfo.realname,'姓名')" style="color: #2A7AFF;">{{ date.userInfo.realname }}</div>
							</div>
							<div class="name name2">
								<div class="xm-box">
									<img src="../../assets/image/gender.png" alt="">
									<div class="xm">性别：</div>
								</div>
								<div class="xm">{{ date.userInfo.sex == 1 ? '男' : (date.userInfo.sex == 2 ? '女' : '') }}
								</div>
							</div>
						</div>
					</div>
					<div style="width: 100%;height: 15PX;"></div>
				</div>
			</div>

			<div class="nav">
				<div @click="getnav(1)" :class="navIndex == 1 ? 'select' : ''">裁判员</div>
				<div @click="getnav(2)" :class="navIndex == 2 ? 'select' : ''">运动员</div>
				<!-- <div @click="getnav(3)" :class="navIndex == 3 ? 'select' : ''">教练员</div> -->
			</div>

			<div v-if="navIndex == 1">
				<div class="hd" v-if="date.certificateList.length > 0">

					<div class="zuo" @click="getzuo">
						<img src="../../assets/image/zuo.png" alt="">
					</div>
					<div class="xx" ref="xxContainer">
						<div class="diyc-box" v-for="(item, index) in this.date.certificateList" :key="index"
							v-if="index >= currentIndex && index < currentIndex + 1">
							<div class="diyc">
								<div class="diyczuo">
									<img src="../../assets/image/logo.png" alt="">
									<div class="name">{{ item[0].sportName }}</div>
								</div>
								<div class="diycyou">{{ item[0].referee_level }}</div>
							</div>
							<div class="diycxian"></div>
							<div class="diyctitle" @click="getcertificate(item[0].certificate_unit,'发证单位')" style="display: flex;">
								发证单位：
								<span style="color: #2A7AFF;width: 56%;display: block;overflow: hidden; white-space: nowrap; white-space: nowrap; overflow: hidden;text-overflow: ellipsis;">{{ item[0].certificate_unit }}</span>
							</div>
							<div class="diyctitle">发证日期：{{ item[0].certificate_date | format }}</div>
							<div class="diyctitle">注册所在区：{{ item[0].certificate_area }}</div>
						</div>
					</div>

					<div class="you" @click="getyou">
						<img src="../../assets/image/you.png" alt="">
					</div>
				</div>

				<div class="navt" style="width: auto;">
					<div class="navtxian"></div>
					<div class="navtname">执裁记录</div>
				</div>

				<div class="arbitration-box" v-for="(item, index) in this.date.enforcementList" :key="index">
					<div>
						<div class="arbitration" @click="getarbitration(item[0])">
							<div class="arbitrationname">
								{{ item[0].match_sport }}
							</div>
							<div :class="item[0].arbitration ? 'triangle-down' : 'triangle-right'"></div>
						</div>
						<div class="timeline" v-for="(res, indexs) in item" :key="indexs" v-if="item[0].arbitration">
							<el-timeline>
								<el-timeline-item :timestamp="res.match_date" color="#0063FF">
									{{ res.match_name }}
								</el-timeline-item>
							</el-timeline>
						</div>
					</div>
				</div>

				<div v-if="this.$verification(this.date.enforcementList)" class="zx-box" style="margin-top: 0;">
					<img class="zw" src="../../assets/image/333.png" alt="">
					<div class="zwxx">暂无信息</div>
				</div>

				<div class="navt" style="margin-top: 0;">
					<div class="navtxian"></div>
					<div class="navtname">晋升记录</div>
				</div>

				<div class="arbitration-box promotion" v-for="(item, index) in this.date.promotionList" :key="index">
					<div>
						<div class="arbitration" @click="getarbitration(item[0])">
							<div class="arbitrationname">
								{{ item[0].sportsName }}
							</div>
							<div :class="item[0].arbitration ? 'triangle-down' : 'triangle-right'"></div>
						</div>
						<div class="timelines" v-if="item[0].arbitration" v-for="(res, indexs) in item" :key="indexs">
							<div>
								<div class="jtxx-box">
									<div class="jtxxy">
										<div class="jb">
											<img src="../../assets/image/111.png" alt="">
											<div style="flex-shrink: 0;">晋升级别：</div>
											<div>{{ res.refereeLevel }}</div>
										</div>
										<div class="rq">
											<img src="../../assets/image/222.png" alt="">
											<div style="flex-shrink: 0;">发证日期：</div>
											<div>{{ res.certificateDate | format }}</div>
										</div>
									</div>
									<div class="dz">
										<img src="../../assets/image/99.png" alt="" style="margin-right: 10PX;">
										<div style="flex-shrink: 0;">发证单位：</div>
										<div>{{ res.certificateUnit }}</div>
									</div>
								</div>
								<div class="jtxxxian" v-if="indexs != item.length - 1"></div>
								<div class="jtxxkb" v-if="indexs == item.length - 1"></div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="this.$verification(this.date.promotionList)" class="zx-box" style="margin-top: 0;">
					<img class="zw" src="../../assets/image/333.png" alt="">
					<div class="zwxx">暂无信息</div>
				</div>
			</div>

			<div v-if="navIndex == 2">
				<div class="zx-box" v-if="Object.keys(this.date.sportsman).length === 0">
					<img class="zw" src="../../assets/image/333.png" alt="">
					<div class="zwxx">暂无信息</div>
				</div>
				<div class="ydy-box" style="width: auto;" v-else>
					<div class="ydy ydys" v-for="(item, index) in this.date.sportsman.sportsList" :key="index">
						<div class="name-box">
							<div class="ydyxian"></div>
							<div class="name">{{item}}</div>
						</div>
						<div class="ydybut">
							<div class="ydybutname" @click="getentry(item)">参赛记录</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="navIndex == 3" class="zx-box">
				<img class="zw" src="../../assets/image/333.png" alt="">
				<div class="zwxx">暂无信息</div>
			</div>

		</div>

		<div class="therightside" v-if="superiorindex == 1">
			<div>
				<div class="title">
					<img src="../../assets/image/title4.png" alt="">
					<p>相关裁判</p>
				</div>
				<div class="box" style="height: auto;">
					<div class="berelatedto-box">
						<div class="berelatedto" v-for="(item, index) in refereeList" :key="index" @click="query(item)">
							<img :src="item.image" alt="" class="berelatedto-logo">
							<div class="berelatedto-name">{{ item.refereeName }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<el-dialog :visible.sync="dialogVisible" width="90%" :before-close="handleClose"  top="5vh">
			<table border="1" class="table-xj" v-for="(item, index) in tableData" :key="index">
					<tr>
						<td class="dialogtitle">项目</td>
						<td class="content">{{ item.sports }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">赛事名称</td>
						<td class="content">{{ item.matchName }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">赛事时间</td>
						<td class="content">
							{{ item.matchDateStart }} 至 {{ item.matchDateEnd }}
						</td>
					</tr>
					<tr>
						<td class="dialogtitle">地点</td>
						<td class="content">{{ item.matchAddressStadium }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">组别</td>
						<td class="content">{{ item.gameGroup }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">小项</td>
						<td class="content">{{ item.event }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">赛次（轮次）</td>
						<td class="content">{{ item.games }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">代表队</td>
						<td class="content">{{ item.delegation }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">姓名</td>
						<td class="content">{{ item.names }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">成绩</td>
						<td class="content">{{ item.grade }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">名次</td>
						<td class="content">{{ item.ranking }}</td>
					</tr>
					<tr>
						<td class="dialogtitle">备注</td>
						<td class="content">{{ item.remarks }}</td>
					</tr>
				</table>
		</el-dialog>
	</div>
</template>

<script>
import H5header from "./components/header2.vue"
export default {
	name: '',
	components: {
		H5header
	},
	data() {
		return {
			reverse: false,
			date: {
				certificateList: [],
				enforcementList: [],
				userInfo: {},
				sportsman:{}
			},
			info: {
				pageNo: 1,
				pageSize: 12,
				referee_level: '',
				certificate_area: '',
				sports: '',
				refereeName: '',
				notInIds: ''
			},
			refereeList: [],
			options2:[],
			options3:[],
			id: '',
			navIndex: 1,
			dialogVisible: false,
			tableData: [],
			superiorindex: 1,
			currentIndex: 0,
			realnameLength:true
		}
	},
	filters: {
		format(x) {
			if (x != null && x != '') {
				let time = x.split(" ")[0]
				let parts = time.split('-');
				let result = parts[0] + '-' + parts[1];
				return result
			}
		}
	},
	created() {
		this.judgmentLevel()
		this.areaList()
		this.superiorindex = this.$route.query.navIndex
		this.info.sports = this.$route.query.sports
		this.info.notInIds = this.$route.query.id
		setTimeout(() => {
			this.id = this.$route.query.id
		}, 1000)
		this.getUserAllRefereeDeta(this.$route.query.id)
	},
	methods: {
		judgmentLevel(){
			this.$api.judgmentLevel().then(res => {
				this.options2 = res.data.data.result
			})
		},
		areaList() {
			this.$api.areaList().then(res => {
				this.options3 = res.data.data.result
			})
		},
		getUserAllRefereeDeta(e) {
			this.$api.getUserAllRefereeDeta({ userId: e }).then(res => {
				if(res.data.data.result.userInfo.avatar){
					this.$api.getFile({ objectName: res.data.data.result.userInfo.avatar }).then(ess => {
						this.$convert(ess.data.data.result.url).then((fileUrl) => {
							res.data.data.result.userInfo.avatar = fileUrl
							this.$forceUpdate()
						})
					})
				}else{
					res.data.data.result.userInfo.avatar = require('@/assets/image/defaults.png')
					this.$forceUpdate()
				}
				res.data.data.result.certificateList.forEach(item => {
					item.forEach(err => {
						err.realName = this.$decrypt(err.realName || '')
						err.certificate_unit = this.$decrypt(err.certificate_unit || '')

						for (let i in this.options2) {
							if (err.referee_level == this.options2[i].itemValue) {
								err.referee_level = this.options2[i].itemText
							}
						}
						for (let i in this.options3) {
							if (err.certificate_area == this.options3[i].itemValue) {
								err.certificate_area = this.options3[i].itemText
							}
						}
					})
				})

				res.data.data.result.enforcementList.forEach(item => {
					item.forEach(res => {
						let match_date = JSON.parse(res.match_date)
						let arr = match_date.map(err => { return err.startDate + ' 至 ' + err.endDate })
						res.match_date = arr.join("，")
						res.arbitration = true
					})
				})

				res.data.data.result.promotionList.forEach(item => {
					item.forEach(res => {
						res.certificateUnit = this.$decrypt(res.certificateUnit || '')
						res.arbitration = true
					})
				})
				res.data.data.result.userInfo.realname = this.$decrypt(res.data.data.result.userInfo.realname || '')
				if( res.data.data.result.userInfo.realname.length > 4){
                    this.realnameLength = false
                }else{
                    this.realnameLength = true
                }
				this.date = res.data.data.result

				let img = 'https://admin.bjcac.org.cn/jeecgboot/jeecg-system/sys/common/staticMinioFile?minFileName=' + res.data.data.result.avatar
				this.$getwxsdk(res.data.data.result.refereeName + '- 裁判详情 - 北京市体育竞赛管理和国际交流中心', img)
			})
		},
		query(item) {
			this.info.sports = item.sports
			setTimeout(() => {
				this.id = item.refereeId
			}, 1000)
			this.info.notInIds = item.refereeId
			this.getUserAllRefereeDeta(item.refereeId)
			window.scrollTo(0, 0)
		},
		getnav(e) {
			this.navIndex = e
		},
		getarbitration(e) {
			e.arbitration = !e.arbitration
		},
		getentry(item) {
			this.dialogVisible = true
			let data = JSON.parse(JSON.stringify(this.date.sportsman))
			data.sports = item
			this.$api.getSportsmanScore(data).then(res => {
				res.data.data.result.forEach(item => {
					item.names = this.$decrypt(item.names || '')
					item.matchAddressStadium = item.addresses.map(items => {
						return items.matchAddressStadium
					}).join('、')
				})
				this.tableData = res.data.data.result
			})
		},
		handleClose() {
			this.dialogVisible = false
		},
		getzuo() {
			if (this.currentIndex > 0) {
				this.currentIndex -= 1;
			}
		},
		getyou() {
			if (this.currentIndex + 1 < this.date.certificateList.length) {
				this.currentIndex += 1;
			}
		},
		getcertificate(e,title) {
			this.$alert(e, title, {
				confirmButtonText: '关闭',
				callback: action => { }
			});
		}
	}
}
</script>

<style scoped lang="scss">
.back {
	width: 1200PX;
	margin: 0 auto;
	display: flex;
	margin-bottom: 100PX;
}

.theleftside {
	margin: 0 16PX;
}

.title {
	// width: 836PX;
	height: 57PX;
	background: #D8E7FF;
	border-radius: 22PX 22PX 0PX 0PX;
	display: flex;
	align-items: center;
}

.top {
	margin-top: 19PX;
}

.title img {
	width: 18PX;
	height: 21PX;
	margin-left: 33PX;
}

.title p {
	font-size: 16PX;
	font-weight: 500;
	color: #0063FF;
	line-height: 36PX;
	margin-left: 13PX;
}

.box {

	background: #F8FBFF;
	border-bottom-left-radius: 22PX;
	border-bottom-right-radius: 22PX;
}

.box-back {
	height: 160PX;
	background: url('../../assets/image/coach.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin: 0 7PX;
	display: flex;
	padding-bottom: 15PX;
}

.box-logo {
	width: 76PX;
	height: 105PX;
	border-radius: 18PX;
	margin-top: 30PX;
	margin-left: 25PX;
}

.box-title {
	margin-left: 21PX;
	margin-top: 15PX;
	min-width: 170PX;
	// width: calc(100% - 168PX);
	width: calc(100% - 198PX);

	.name {
		display: flex;
		// width: 170PX;
		border-bottom: 1PX solid rgba(110, 110, 110, 0.48);
		height: 30PX;
		display: flex;
		justify-content: space-between;
		margin-top: 25PX;

		img {
			width: 16.2PX;
			height: 18.3PX;
			margin-right: 10PX;
		}

		.xm-box {
			display: flex;

			.xm {
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 14PX;
				color: #000000;
				margin-right: 15PX;
				flex-shrink: 0;
			}
		}

		.xm {
			font-family: SourceHanSansCN;
			font-weight: 500;
			font-size: 14PX;
			color: #000000;
			margin-right: 15PX;
		}

		.xms{
			width: 40%;
			white-space: nowrap; /* 确保文本在一行内显示 */
			overflow: hidden; /* 隐藏超出容器的文本 */
			text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
			text-align: right;
		}
	}

	.name2 {
		margin-top: 25PX;
	}
}

.nav {
	display: flex;
	height: 35PX;
	width: calc(100% - 40PX);
	background: #E8F1FF;
	border-radius: 32PX;
	margin: 10PX 20PX 0 20PX;

	div {
		font-family: Microsoft YaHei;
		font-weight: 400;
		font-size: 15PX;
		width: 33.3%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #0576F0;
	}

	.select {
		background: #0576F0;
		border-radius: 32PX;
		color: #FFF;
	}
}

.hd {
	margin-top: 20PX;
	display: flex;

	.zuo {
		width: 32PX;
		height: 32PX;
		margin-top: 35PX;
		cursor: pointer;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.you {
		width: 32PX;
		height: 32PX;
		margin-top: 35PX;
		cursor: pointer;
		flex-shrink: 0;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.xx {
		overflow: hidden;
		width: calc(100% - 48PX);

		.diyc-box {
			width: calc(100% - 48PX);
			height: 150PX;
			background: url('../../assets/image/88.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			margin: 0 24PX;

			.diyc {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;

                .diyczuo {
                    display: flex;
                    align-items: center;
                    padding-top: 18PX;
                    padding-left: 12PX;

                    img {
                        width: 25PX;
                        height: 22PX;
                    }

                    .name {
                        font-family: SourceHanSansCN;
                        font-weight: bold;
                        font-size: 15PX;
                        color: #1469FF;
                        margin-left: 10PX;
                    }
                }

                .diycyou {
                    width: 60PX;
                    height: 20PX;
                    background: url('../../assets/image/77.png');
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    font-family: SourceHanSansCN;
                    font-weight: bold;
                    font-size: 12PX;
                    color: #FFFFFF;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    right: 38PX;
                }
            }

			.diycxian {
				width: calc(100% - 38PX);
				height: 1PX;
				background: #7FB5FD;
				border-radius: 0PX;
				margin: 10PX 19PX 15PX 19PX;
			}

			.diyctitle {
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 14PX;
				color: #5E5E5E;
				margin-bottom: 5PX;
				margin-left: 19PX;
			}
		}
	}
}

.navt {
	width: 100%;
	height: 50PX;
	background: #FFFFFF;
	box-shadow: 5PX 14PX 18PX 3PX rgba(170, 202, 237, 0.16);
	border-radius: 19PX;
	margin: 20PX 0 20PX 0;
	display: flex;
	align-items: center;

	.navtxian {
		width: 8PX;
		height: 20PX;
		background: #2A7AFF;
		border-radius: 5PX;
		margin-left: 38PX;
	}

	.navtname {
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 15PX;
		color: #2A7AFF;
		margin-left: 21PX;
	}
}

.arbitration-box {
	width: 100%;
	margin-bottom: 19PX;

	.arbitration {
		min-width: 85PX;
		height: 30PX;
		background: linear-gradient(150deg, #0D63FF, #3280FF);
		border-radius: 19PX 19PX 19PX 0PX;
		display: inline-block;
		cursor: pointer;
		line-height: 30PX;
		margin-left: 20PX;
		padding: 0 19PX;
	}

	.arbitrationname {
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 15PX;
		color: #FFFFFF;
		margin-right: 8PX;
		display: inline-block;
	}

	.triangle-down {
		width: 0;
		height: 0;
		border-left: 4PX solid transparent;
		/* 左边框为透明，宽度为4PX */
		border-right: 4PX solid transparent;
		/* 右边框为透明，宽度为4PX */
		border-top: 7PX solid #FFFFFF;
		/* 上边框为白色，高度为7PX */
		display: inline-block;
		position: relative;
		top: -2PX;
	}

	.triangle-right {
		width: 0;
		height: 0;
		border-top: 4PX solid transparent;
		/* 上边框为透明，高度为4PX */
		border-bottom: 4PX solid transparent;
		/* 下边框为透明，高度为4PX */
		border-left: 7PX solid #FFFFFF;
		/* 左边框为白色，宽度为7PX */
		display: inline-block;
		position: relative;
		top: -2PX;
	}

	.timeline {
		margin-top: 20PX;
		margin-left: 45PX;
		padding: 0 !important;

		::v-deep .el-timeline-item__content {
			font-family: SourceHanSansCN;
			font-weight: 500;
			font-size: 16PX;
			color: #000000;
		}

		::v-deep .el-timeline-item__timestamp {
			font-family: SourceHanSansCN;
			font-weight: 500;
			font-size: 14PX;
			color: #9B9B9B;
		}

		::v-deep .el-timeline-item__tail {
			border-left: 2PX solid #0063FF !important;
		}

		::v-deep .el-timeline-item {
			padding-bottom: 20PX !important;
		}
	}
}

.promotion {

	.timelines {
		margin-top: 20PX;

		.jtxx-box {
			margin-left: 35PX;

			.jtxxy {
				display: flex;
				align-items: center;
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 14PX;
				color: #000000;

				.jb {
					display: flex;
					align-items: center;

					img {
						width: 20.2PX;
						height: 26.3PX;
					}

					div:nth-child(3) {
						color: #2D7CFF;
					}
				}

				.rq {
					display: flex;
					position: absolute;
					margin-left: 40%;

					img {
						width: 22.1PX;
						height: 20.5PX;
					}
				}
			}

			.dz {
				display: flex;
				align-items: center;
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 14PX;
				color: #000000;
				margin-top: 20PX;

				img {
					width: 26PX;
					height: 20PX;
				}

			}

			img {
				margin-right: 15PX;
			}
		}

		.jtxxxian {
			width: calc(100% - 52PX);
			height: 1PX;
			background: rgba(211, 211, 211, 0.4);
			border-radius: 0PX;
			margin: 20PX 0 20PX 35PX;
		}
	}
}

.ydy-box {
	margin: 47PX 0 0 0;
	display: flex;
	flex-wrap: wrap;


	.ydy {
		width: 48%;
		height: 130PX;
		background: url('../../assets/image/555.png');
		background-repeat: no-repeat;
		background-size: 100% 100%;
		margin-right: 10PX;
		margin-bottom: 20PX;

		.name-box {
			display: flex;
			align-items: center;
			margin-left: 26PX;
			padding-top: 14PX;

			.ydyxian {
				width: 4PX;
				height: 16PX;
				background: #417BC8;
				border-radius: 3PX;
			}

			.name {
				font-family: SourceHanSansCN;
				font-weight: bold;
				font-size: 15PX;
				color: #408BFF;
				line-height: 36PX;
				background: linear-gradient(150deg, #336EBC 0.146484375%, #81B7FF 100%);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				margin-left: 10PX;
			}
		}

		.ydybut {
			width: 109PX;
			height: 26PX;
			background: url('../../assets/image/444.png');
			background-repeat: no-repeat;
			background-size: 100% 100%;
			display: flex;
			align-items: center;
			margin-top: 33PX;
			margin-left: 25%;
			cursor: pointer;

			.ydybutname {
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 13PX;
				color: #629AE4;
				margin-left: 30PX;
			}
		}
	}

	.ydy:nth-child(2n) {
		margin-right: 0;
	}
}

.zx-box {
	margin-top: 40PX;
	display: flex;
	flex-direction: column;
	align-items: center;

	.zw {
		width: 257PX;
		height: 162PX;
	}

	.zwxx {
		font-family: SourceHanSansCN;
		font-weight: 500;
		font-size: 15PX;
		color: #989898;
		margin-top: 27PX;
		margin-bottom: 40PX;
	}
}


.box-name {
	font-size: 12PX;
	font-weight: 400;
	color: #000;
	margin-bottom: 5PX;
}

.ststus {
	font-size: 14PX;
	font-weight: 400;
	color: #0063FF;
	margin-top: 14PX;
	margin-left: 22PX;
	padding-bottom: 15PX;
}

.none {
	font-size: 22PX;
	font-weight: 400;
	color: #ACACAC;
	margin-left: 93PX;
	padding: 36PX;
}

.timeline {
	padding: 35PX;
}

::v-deep .el-timeline-item__tail {
	border-left: 2PX solid #0063FF !important;
}

::v-deep .el-timeline-item__content {
	font-size: 15PX;
	font-weight: 400;
	color: #000000;
}

::v-deep .el-timeline-item__timestamp {
	font-size: 13PX;
	font-weight: 400;
	color: #ACACAC;
}

.therightside {
	margin: 16PX 19PX;
}

.berelatedto-box {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-top: 18PX;
	margin: 0 11PX;
}

.berelatedto {
	margin-bottom: 27PX;
	// margin-right: 25PX;
}

.berelatedto:nth-child(2n) {
	margin-right: 0;
}

.berelatedto-logo {
	width: 144PX;
	height: 169PX;
	border-radius: 18PX;
}

.berelatedto-name {
	font-size: 15PX;
	font-weight: 400;
	color: #000000;
	margin-top: 13PX;
	width: 144PX;
	text-align: center;
}

::v-deep .el-timeline-item__timestamp {
	line-height: 20PX;
}

.table-xj {
	width: 100%;
	margin-bottom: 20PX;

	tr {
		height: 30PX;
	}

	.dialogtitle {
		color: #fff;
		background: #0D6DF5;
		width: 90PX;
		text-align: center;
	}

	.content {
		text-align: center;
		padding: 0 5PX;
		color: #333333;
		word-break: break-all;
		overflow-wrap: break-word;
	}
}
::v-deep .el-dialog__body{
	max-height: 85vh;
    overflow: hidden;
    overflow-y: auto;
}
</style>
